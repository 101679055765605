import React, { useEffect, useState } from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

import styles from "./project.module.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

var settings = {
  dots: true,
  infinite: false,
  autoplay: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

const Project = ({ title, subtitle, mainImage, index, link, imageGallery }) => (
  <div className={styles.wrapper}>
    <div className={(index + 1) % 2 ? "" : styles.padding} />
    {link ? (
      <a href={link} target="_blank">
        <h2 className={styles.title}>{title} →</h2>
      </a>
    ) : (
      <h2 className={styles.title}>{title}</h2>
    )}

    <h3 className={styles.subtitle}>{subtitle}</h3>

    {imageGallery && imageGallery.length > 0 && (
      <>
        <Slider {...settings} className={styles.slider}>
          {imageGallery.map((image, i) => {
            return (
              <img key={i} className={styles.galleryImg} src={image.asset.url} alt={"hello"} />
            );
          })}
        </Slider>
      </>
    )}

    <div className={styles.imgContainer}>
      {mainImage && mainImage.asset && imageGallery.length <= 0 && (
        <img
          className={styles.img}
          src={imageUrlFor(buildImageObj(mainImage)).auto("format").url()}
          alt={mainImage.alt}
        />
      )}
    </div>
  </div>
);

export default Project;
