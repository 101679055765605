import React, { useState } from "react";
import Button from "./button";
import styles from "./contact-form.module.css";

const ContactForm = props => {
  const [values, setValues] = useState({ name: "", email: "", message: "" });

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <>
      <form name="contact" method="POST" data-netlify="true" className={styles.form}>
        <input type="hidden" name="form-name" value="contact" />
        <div className={styles.formRow}>
          <div className={styles.formCol} style={{ flex: 1 }}>
            <input
              type="text"
              name="name"
              placeholder="Your name"
              className={styles.input}
              required
            />
          </div>
          <div className={styles.formCol} style={{ flex: 2 }}>
            <input
              type="email"
              name="email"
              placeholder="Your e-mail"
              className={styles.input}
              required
            />
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.formCol} style={{ flex: 2 }}>
            <textarea
              rows="5"
              maxLength="400"
              name="message"
              value={values.msg}
              placeholder="Tell me about your project"
              className={styles.textarea}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={styles.formCol} style={{ flex: 1 }}>
    
            <Button submit={true} text={"Send"} className={styles.formBtn}/>
          </div>
        </div>
      </form>
    </>
  );
};

export default ContactForm;
