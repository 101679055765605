import React from "react";
import Project from "./project";

import styles from "./projects.module.css";

const Projects = ({ projects }) => (
  <>
    <div className={styles.projects}>
      {projects &&
        projects.map((p, i) => {
          return (
            <Project
              key={p.id}
              index={i}
              title={p.title}
              subtitle={p.subtitle}
              link={p.link}
              mainImage={p.mainImage}
              imageGallery={p.imageGallery}
            />
          );
        })}
    </div>
  </>
);

export default Projects;
